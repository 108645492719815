import { HighlightCard as HighlightCardType } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { GridContainer, GridElement } from 'components/Grid';
import * as React from 'react';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './HighlightCard.module.scss';
import { HighlightControls } from './HighlightControls';
import { fixedAspectRatioImageLoader, loaderUrl } from 'utils/cloudinary';
import Image from 'next/image';
import { idFromPointer } from 'utils/pointer';
import { ImpressionAnalyticsContextProvider } from 'contexts/ImpressionAnalyticsContext';
import { Observable as ImpressionObservable } from 'contexts/ImpressionAnalyticsContext/Observable';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';

type ImageAspectRatio = '16:9' | '1:1';
export type ImagePosition = 'left' | 'right';

function getSizes(aspectRatio: ImageAspectRatio): string {
  switch (aspectRatio) {
    case '1:1':
      return '(min-width: 1020px) 23.2vw, (min-width: 480px) 25.31vw, 50.625vw';

    case '16:9':
      return '(min-width: 1020px) 41.25vw, (min-width: 480px) 45vw, 90vw';
  }
}

type Props = {
  card: HighlightCardType;
  cardKey: string;
  imagePosition: ImagePosition;
  uiPositionindex: number;
};

export const HighlightCard: React.FunctionComponent<Props> = ({
  card,
  cardKey,
  imagePosition,
  uiPositionindex,
}) => {
  const headingId = sanitizeHtmlId(cardKey + 'title');
  const controls = card.controls || [];
  const imageAspectRatio: ImageAspectRatio = card.style?.image || '16:9';

  return (
    <AnalyticsContextProvider context={card.analytics?.context?.comscore}>
      <ImpressionAnalyticsContextProvider
        uiElementPosition={uiPositionindex}
        uiElementType="highlightCard"
      >
        <ImpressionObservable
          key={cardKey}
          yleId={idFromPointer(card.pointer) || undefined}
          itemPosition={0}
        >
          <div role="group" aria-labelledby={headingId} className={styles.root}>
            <Container>
              <GridContainer>
                <GridElement mobile={0} desktopSmall={1} />

                <GridElement
                  mobile={12}
                  mobileLandscape={6}
                  orderMobileLandscape={imagePosition === 'right' ? 1 : 0}
                  desktopSmall={11}
                >
                  {card.image && (
                    <div className={styles.imageContainer}>
                      <Image
                        alt={card.image.alt || ''}
                        className={styles.image}
                        fill
                        loader={fixedAspectRatioImageLoader(imageAspectRatio)}
                        sizes={getSizes(imageAspectRatio)}
                        src={loaderUrl(card.image)}
                      />
                    </div>
                  )}
                </GridElement>

                <GridElement
                  mobile={0}
                  desktop={imagePosition === 'left' ? 1 : 0}
                />

                <GridElement
                  mobile={12}
                  mobileLandscape={6}
                  desktopSmall={11}
                  desktop={10}
                  desktopXL={10}
                >
                  <div className={styles.textContentWrapper}>
                    <div className={styles.textContent}>
                      <h3 className={styles.title} dir="auto" id={headingId}>
                        {card.title}
                      </h3>
                      {card.description ? (
                        <p className={styles.description} dir="auto">
                          {card.description}
                        </p>
                      ) : null}
                      {controls.length > 0 && (
                        <div className={styles.controls}>
                          <HighlightControls controls={controls} />
                        </div>
                      )}
                    </div>
                  </div>
                </GridElement>

                <GridElement
                  mobile={0}
                  desktop={imagePosition === 'right' ? 1 : 0}
                />
              </GridContainer>
            </Container>
          </div>
        </ImpressionObservable>
      </ImpressionAnalyticsContextProvider>
    </AnalyticsContextProvider>
  );
};
